import {
  Component,
  OnInit,
  HostListener,
  HostBinding,
  Inject,
  AfterViewInit
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { WINDOW_PROVIDERS, WINDOW } from "../../helpers/window.helpers";
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { auth } from '@app/firebase-initialization'; // Import Firebase auth

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, AfterViewInit {
  isFixed: boolean;
  public isCollapsed = true;
  private pendingFragment: string | null = null;
  isLoggedIn: boolean = false; // Track login state

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const fragment = this.router.url.split('#')[1];
      if (fragment) {
        this.pendingFragment = fragment;
        this.scrollToFragment();
      }
    });

    this.authStateListener(); // Start listening to auth state changes
  }

  ngAfterViewInit() {
    if (this.pendingFragment) {
      this.scrollToFragment();
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    this.isFixed = offset > 10;
  }

  @HostBinding("class.menu-opened") menuOpened = false;

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  hidemenu() {
    this.isCollapsed = true;
  }

  openSearch() {
    this.router.navigateByUrl('/search');
  }

  openLogin() {
    this.router.navigateByUrl('/sessions/signin');
  }

  openProfile() {
    this.router.navigateByUrl('/sessions/profile');
  }

  navigateAndScroll(route: string, fragment: string) {
    this.hidemenu();
    this.pendingFragment = fragment;
    this.router.navigate([route], { fragment: fragment }).then(() => {
      this.scrollToFragment();
    });
  }

  scrollToFragment() {
    setTimeout(() => {
      if (this.pendingFragment) {
        const element = this.document.querySelector("#" + this.pendingFragment);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          this.pendingFragment = null;
        }
      }
    }, 0);
  }

  authStateListener() {
    auth.onAuthStateChanged((user) => {
      this.isLoggedIn = !!user;
    });
  }
}
