<div class="search_wrap">
  <app-header></app-header>
  <div id="searchbox" class="search-input mb-3"></div>
  <div class="container-fluid">
    <!-- Search Input Row -->
    <div class="row">
      <div class="col-md-3 d-none d-md-block">
        <div class="left-panel">
          <div id="clear-refinements" class="mb-3"></div>
          <h4>Sports</h4>
          <div id="sport-list"></div>
          <h4>Day of Week</h4>
          <div id="day-of-week-list"></div>
          <h4>Gender</h4>
          <div id="gender-list"></div>
          <h4>Neighborhood</h4>
          <div id="neighborhood-list"></div>
          <h4>Venue</h4>
          <div id="venue-list"></div>
          <h4>Skill Level</h4>
          <div id="skill-level-list"></div>
          <h4>Tags</h4>
          <div id="tags-list"></div>
        </div>
      </div>
      <div class="col-md-9">
        <button
          class="btn btn-primary d-block d-md-none"
          (click)="openFilters()"
        >
          Filters
        </button>
        <div class="right-panel">
          <div id="stats" class="results-count-container"></div>
          <div id="current-refinements"></div>
          <div id="hits"></div>
          <div id="pagination"></div>
        </div>
      </div>
    </div>
    <!-- Filters Popup -->
    <div class="filters-popup" [ngClass]="{ show: showFilters }">
      <div class="filters-popup-content">
        <button class="close" (click)="closeFilters()">×</button>
        <div class="popup-scrollable-content">
          <h4>Sports</h4>
          <div id="popup-sport-list"></div>
          <h4>Day of Week</h4>
          <div id="popup-day-of-week-list"></div>
          <h4>Gender</h4>
          <div id="popup-gender-list"></div>
          <h4>Neighborhood</h4>
          <div id="popup-neighborhood-list"></div>
          <h4>Venue</h4>
          <div id="popup-venue-list"></div>
          <h4>Skill Level</h4>
          <div id="popup-skill-level-list"></div>
          <h4>Tags</h4>
          <div id="popup-tags-list"></div>
        </div>
        <div class="sticky-footer-divider"></div>
        <div id="popup-clear-refinements" class="mb-3"></div>
        <button
          class="btn btn-primary view-results-button"
          (click)="closeFilters()"
        >
          View Results
        </button>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
