<section class="footer_wrap" id="footer-wrap">
  <div class="container">
    <div class="row">
      <div class="col-md-3 mb-4">
        <div class="o-hidden text-start">
          <div class="">
            <h4 class="card-title fw-bold">Dexam</h4>
            <p class="">
              Dexam is highly customizable angular 14 + Bootstrap 5 landing
              Page. Dexam is built with official tool and uses NG Bootstrap .
            </p>
          </div>
        </div>
      </div>
      <!-- end of col -->
      <div class="col-md-3 mb-4">
        <div class="o-hidden text-start">
          <div class=" ">
            <h4 class="card-title fw-bold">Other Pages</h4>
            <div class="mb-5 lead">
              <ul class="list-inline">
                <li class="mb-3"><a href="#">Marketing</a></li>
                <li class="mb-3"><a href="#">Campaign</a></li>
                <li class="mb-3"><a href="#"> Digital Agency</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- end of col -->
      <div class="col-md-3 mb-4 mb-lg-0 mb-md-0">
        <div class="o-hidden text-start">
          <div class="">
            <h4 class="card-title fw-bold">Awesome Features</h4>
            <div class="mb-5 lead">
              <ul class="list-inline">
                <li class="mb-3">
                  <i class="eva eva-checkmark-outline me-2 text-warning"></i
                  >Minimal
                </li>
                <li class="mb-3">
                  <i class="eva eva-checkmark-outline me-2 text-warning"></i
                  >Intuitive
                </li>

                <li class="mb-3">
                  <i class="eva eva-checkmark-outline me-2 text-warning"></i>
                  Super Flexible
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- end of col -->
      <div class="col-md-3 mb-lg-0 mb-md-0">
        <div class="o-hidden text-start">
          <div class="">
            <h4 class="card-title fw-bold">Contact Info</h4>
            <div class="address-wrap mb-3">
              <ul class="list-group list-unstyled">
                <li class="">
                  <i class="eva eva-pin-outline me-2"></i>UI-Lib ,United Kingdom
                </li>
              </ul>
            </div>
            <div class="address-wrap mb-3">
              <ul class="list-group list-unstyled">
                <li class="">
                  <i class="eva eva-email-outline me-2"></i>testhoo.com
                </li>
              </ul>
            </div>

            <div class="address-wrap mb-3">
              <ul class="list-group list-unstyled">
                <li class="">
                  <i class="eva eva-phone-call-outline me-2"></i> +8801961081993
                </li>
              </ul>
            </div>

            <section class="social-wrap">
              <div class="social-btns-wrap">
                <a class="social-btn text-30 facebook ms-0" href="#"
                  ><i class="eva eva-facebook"></i
                ></a>

                <a class="social-btn text-30 twitter" href="#"
                  ><i class="eva eva-twitter"></i
                ></a>

                <a class="social-btn text-30 google" href="#"
                  ><i class="eva eva-google"></i
                ></a>
              </div>
            </section>
          </div>
        </div>
      </div>
      <!-- end of col -->
    </div>
  </div>
</section>
