import { Component, OnInit, Renderer2 } from "@angular/core";
import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import {
  searchBox,
  hits,
  pagination,
  stats,
  refinementList,
  clearRefinements,
  currentRefinements,
} from "instantsearch.js/es/widgets";
import { environment } from "@env/environment";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  searchTerm: string | null = "";
  locationFilters: { city?: string; state?: string } = {};
  showFilters: boolean = false;
  searchInstance: any;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    const searchClient = algoliasearch(
      environment.algolia.appId,
      environment.algolia.apiKey
    );

    this.searchInstance = instantsearch({
      indexName: environment.algolia.indexName,
      searchClient,
    });

    // Search Box
    this.searchInstance.addWidget(
      searchBox({
        container: "#searchbox",
        placeholder: "Search for activities...",
        showReset: true,
      })
    );

    // Hits
    this.searchInstance.addWidget(
      hits({
        container: "#hits",
        templates: {
          item: `
            <div class="result-item card mb-3">
              <div class="row no-gutters">
                <div class="col-md-4">
                  <img src="{{image_url}}" class="card-img" alt="{{name}}" />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title">{{#helpers.highlight}}{ "attribute": "name" }{{/helpers.highlight}}</h5>
                    <p class="card-text">{{#helpers.highlight}}{ "attribute": "description" }{{/helpers.highlight}}</p>
                    <p class="card-text"><strong>Date:</strong> {{start_date}}</p>
                    <p class="card-text"><strong>Time:</strong> {{time}}</p>
                    <p class="card-text"><strong>Venue:</strong> {{venue}}</p>
                    <p class="card-text"><strong>Price:</strong> {{
                      price_per_person,
                    }}</p>
                  </div>
                </div>
              </div>
            </div>
          `,
        },
      })
    );

    // Pagination
    this.searchInstance.addWidget(
      pagination({
        container: "#pagination",
      })
    );

    // Stats
    this.searchInstance.addWidget(
      stats({
        container: "#stats",
      })
    );

    // Refinement Lists
    this.addRefinementList("sport", "#sport-list");
    this.addRefinementList("day_of_week", "#day-of-week-list");
    this.addRefinementList("gender", "#gender-list");
    this.addRefinementList("neighborhood", "#neighborhood-list");
    this.addRefinementList("venue", "#venue-list");
    this.addRefinementList("skill_level", "#skill-level-list");
    this.addRefinementList("tags", "#tags-list");

    // Current Refinements
    this.searchInstance.addWidget(
      currentRefinements({
        container: "#current-refinements",
      })
    );

    // Clear Refinements
    this.searchInstance.addWidget(
      clearRefinements({
        container: "#clear-refinements",
      })
    );

    this.searchInstance.start();
  }

  addRefinementList(attribute: string, container: string) {
    this.searchInstance.addWidget(
      refinementList({
        container,
        attribute,
      })
    );
  }

  openFilters() {
    this.showFilters = true;
    this.renderer.addClass(document.body, "no-scroll");
  }

  closeFilters() {
    this.showFilters = false;
    this.renderer.removeClass(document.body, "no-scroll");
  }
}
